
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import IconSpeed from '@/assets/images/home/icon_speed.png';
import IconStyle from '@/assets/images/icon_style_title.png';
import IconLeftArrow from '@/assets/images/arrow_left.png';
import IconRightArrow from '@/assets/images/arrow_right.png';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import { ElMessage } from 'element-plus';
import { getDomesticGoodsDetail } from '@/modules/resource-manage/domestic-goods/api';
import { IGetDomesticGoodsInfoRes } from '@/modules/resource-manage/domestic-goods/api/type.d';
import { usePictureOperation } from '../composables/use-picture-operation';
import SelectImgDialog from '@/modules/miaokuan-manage/common/components/select-img-dialog/index.vue';
import DescriptionCard from './description-card.vue';

export default defineComponent({
  name: 'jd',
  components: {
    Swiper,
    SwiperSlide,
    SelectImgDialog,
    DescriptionCard,
  },
  emits: ['select-img'],
  setup() {
    const router = useRouter();
    const route = useRoute();
    const handleRouteToList = () => {
      router.push({
        name: 'BrandDetail',
        query: {
          brandName: route.query?.brandName,
        },
      });
    };
    // 轮播灯箱图选中项indexs
    const currentIndex = ref(0);
    // 轮播灯箱组件
    const swiperEl = ref<any>(null);
    // 详细信息
    const info = ref<IGetDomesticGoodsInfoRes | null>(null);

    // 获取页面详细信息
    const getInfo = async () => {
      currentIndex.value = 0;
      info.value = {} as IGetDomesticGoodsInfoRes;
      const spu = route.query?.id as string;
      const { data } = await getDomesticGoodsDetail({ spu });
      if (data) {
        info.value = data;
      } else {
        ElMessage.error('商品不存在');
      }
    };

    const handleItem = (index: number) => {
      if (index !== currentIndex.value) {
        currentIndex.value = index;
      }
    };
    const handleNext = () => {
      if (!info.value) return;
      if (currentIndex.value < info.value.spuPictureLinks.length - 1) {
        currentIndex.value += 1;
        swiperEl.value.slideTo(currentIndex.value);
      }
    };
    const handlePrev = () => {
      if (currentIndex.value > 0) {
        currentIndex.value -= 1;
        swiperEl.value.slideTo(currentIndex.value);
      }
    };

    // 选图和下载图片
    const {
      designTaskOptions,
      selectImgConfig,
      handleSelectImgItem,
      handleDownload,
      handleSelectSuccess,
    } = usePictureOperation();

    const handleLink = (url: string) => {
      window.open(url);
    };

    // 重置灯箱图滚动器位置
    const initSwiper = (el: any) => {
      swiperEl.value = el;
    };

    const init = () => {
      getInfo();
    };
    init();

    return {
      info,
      designTaskOptions,
      selectImgConfig,
      handleSelectImgItem,
      handleDownload,
      handleSelectSuccess,
      handleLink,
      initSwiper,
      handleNext,
      handlePrev,
      handleItem,
      modules: [Navigation],
      currentIndex,
      getInfo,
      handleRouteToList,
    };
  },
  render() {
    return (
      <div>
        <div class="breadcrumb">
          <span>妙款 / </span>
          <span style="cursor: pointer;" onClick={this.handleRouteToList}>
            品牌 /
          </span>
          <span class="breadcrumb_active"> 商品详情</span>
        </div>
        {this.info?.spu && (
          <div class="container">
            <div class="header">
              <div class="header-left">
                <div class="productName">
                  <a href={this.info.spuLink} target='_blank'>{this.info.spuName}</a>
                  <svg class="iconfont icon" aria-hidden="true">
                    <use xlinkHref="#icon_a-jingdong2"></use>
                  </svg>
                </div>
                <div class="shopName">
                  <i class="icon"></i>
                  <span>{this.info.storeName}</span>
                </div>
              </div>
              <div class="category">
                品类：{this.info.categoryClass1Name}/{this.info.categoryClass2Name}/{this.info.categoryClass3Name}
              </div>
              <div class="picture-operation">
                <div class="btns">
                  <div
                    class="select choose-image"
                    onClick={
                      () => this.handleSelectImgItem({
                        key: this.info?.spu,
                        channelId: this.info?.channelId as string,
                        category: this.info?.categoryClass2Name || this.info?.categoryClass1Name,
                      })
                    }
                  >
                    选图
                  </div>
                  <el-tooltip content="添加到最近一次选图分组" placement="bottom-start">
                    <div
                      class="quick"
                      onClick={
                        () => this.handleSelectImgItem(
                          {
                            key: this.info?.spu,
                            channelId: this.info?.channelId as string,
                            category: this.info?.categoryClass2Name || this.info?.categoryClass1Name,
                          },
                          'quick',
                        )
                      }
                    >
                      <img src={IconSpeed} />
                    </div>
                  </el-tooltip>
                </div>
                <div class="btns">
                  <el-button
                    className="select download-btn"
                    onClick={
                      () => this.handleDownload({
                        name: this.info?.spuName, id: this.info?.spu,
                      })
                    }
                  >
                    下载图片
                  </el-button>
                </div>
              </div>
            </div>
            <section class="main">
              <div class="cover_layout">
                <div class="main-title">
                  <div class="title-left flex">
                    <img src={IconStyle} class="icon" />
                    <div>商品详情</div>
                  </div>
                </div>
                <custom-image
                  hide-on-click-modal={true}
                  src={this.info.spuPictureLinks[this.currentIndex]}
                  fit="cover"
                  class="main_cover"
                  initial-index={this.currentIndex}
                  preview-src-list={this.info.spuPictureLinks}
                />
                <div class="theme_layout">
                  <div
                    class={{
                      btn: true,
                      prev: true,
                      disabled: this.currentIndex === 0,
                    }}
                    onClick={this.handlePrev}
                  >
                    <img src={IconLeftArrow} class="icon" />
                  </div>
                  <swiper
                    slides-per-view={5}
                    space-between={12}
                    slideToClickedSlide={true}
                    grabCursor={true}
                    centeredSlides={false}
                    onSwiper={this.initSwiper}
                  >
                    {this.info.spuPictureLinks.map((v: any, i: any) => (
                      <swiper-slide
                        key={i}
                        class="item"
                        v-slots={{
                          default: () => (
                            <div
                              class={{
                                cover: true,
                                active: this.currentIndex === i,
                              }}
                              onClick={() => this.handleItem(i)}
                            >
                              <img src={v} alt="" class="img" />
                            </div>
                          ),
                        }}
                      />
                    ))}
                  </swiper>
                  <div
                    class={{
                      btn: true,
                      next: true,
                      disabled: this.currentIndex >= this.info.spuPictureLinks.length - 1,
                    }}
                    onClick={this.handleNext}
                  >
                    <img src={IconRightArrow} class="icon" />
                  </div>
                </div>
              </div>
              <div class="desc-info">
                <description-card data={this.info} onRefresh={this.getInfo} />
              </div>
            </section>
            <select-img-dialog
              v-model={[this.selectImgConfig.visible, 'visible']}
              data={this.selectImgConfig.data}
              options={this.designTaskOptions}
              onSuccess={this.handleSelectSuccess}
            />
          </div>
        )}
      </div>
    );
  },
});
